<template>
  <div>
    <div style="padding: 0 30px">
      <el-form :model="searchForm" inline>
        <el-form-item :label="$t('应用编号')">
          <el-input
            v-model.trim="searchForm.appId"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.appId = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('短信内容')">
          <el-input
            v-model.trim="searchForm.msgContent"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.msgContent = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('发送号码')">
          <el-input
            v-model.trim="searchForm.mobile"
            :placeholder="$t('请输入')"
            clearable
            @clear="searchForm.mobile = ''"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item :label="$t('归属地')">
          <el-select
            v-model="searchForm.attribution"
            :placeholder="$t('请选择')"
            filterable
            clearable
            @clear="searchForm.attribution = null"
            style="width: 200px"
          >
            <el-option
              v-for="(item, key) in dic.province"
              :key="key"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('开始时间')">
          <el-date-picker
            v-model="searchForm.startTime"
            type="datetime"
            :placeholder="$t('开始时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <el-form-item :label="$t('结束时间')">
          <el-date-picker
            v-model="searchForm.endTime"
            type="datetime"
            :placeholder="$t('结束时间')"
            value-format="YYYY-MM-DDTHH:mm:ssZ"
            :disabled-date="
              (time) => {
                return time > new Date();
              }
            "
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="query"
            ><i class="fa-solid fa-magnifying-glass"></i
            >{{ $t("搜索") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- </el-card> -->

    <el-card shadow="hover">
      <!-- 表格 -->
      <el-table
        :data="tableData"
        border
        height="var(--height)"
        class="elTable"
        v-loading="loading"
      >
        <template #empty>
          <el-empty description="" />
        </template>
        <el-table-column
          prop="replyTime"
          :label="$t('回复时间')"
          align="center"
        >
          <template #default="scope">
            {{ dayjs(scope.row.replyTime).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" :label="$t('发送号码')" align="center">
          <template #default="scope">
            <div @click="getMobile(scope.$index)" style="cursor: pointer">
              {{ scope.row.mobile }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="attribution"
          :label="$t('归属地')"
          align="center"
        >
          <template #default="scope">
            {{ idToName(scope.row.attribution, dic.province) }}
          </template>
        </el-table-column>
        <el-table-column prop="extendCode" :label="$t('子号')" align="center" />
        <el-table-column prop="appId" :label="$t('应用编号')" align="center" />
        <el-table-column
          prop="replyContent"
          :label="$t('回复内容')"
          align="center"
          width="300"
        />
        <el-table-column
          prop="msgContent"
          :label="$t('短信内容')"
          align="center"
          width="340"
        >
          <template #default="scope">
            <div style="text-align: left">
              {{ scope.row.msgContent }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="msgContent"
          :label="$t('短信内容')"
          align="center"
        >
          <template #default="scope">
            <div
              :title="scope.row.msgContent"
              style="cursor: copy; text-align: left"
              @click="
                onCopy(scope.row.msgContent);
                ElMessage({ type: 'success', message: $t('复制成功') });
              "
            >
              <vue-ellipsis-3
                :visibleLine="4"
                :text="scope.row.msgContent || '-'"
              >
                <template v-slot:ellipsisNode>
                  <span
                    >...<span
                      style="color: #5f94f2; cursor: pointer"
                      @click.stop="openDetail(scope.row.msgContent)"
                      >{{ $t("详情") }}</span
                    ></span
                  >
                </template>
              </vue-ellipsis-3>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <div style="margin: 0 auto; overflow-x: auto; padding: 20px 0">
        <el-pagination
          v-model:currentPage="searchForm.page"
          v-model:page-size="searchForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          :disabled="false"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="query"
          @current-change="query"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "smsUpLog",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref, onMounted, onActivated } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { getMoLogList } from "@/http/api/smsUplog";
import dic from "@/utils/dictionary";
import { idToName, onCopy, dayjs, encryptPhone } from "@/utils/tool";
import md5 from "js-md5";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

onBeforeMount(() => {
  query();
});
const loading = ref(false);
const tableData = ref([]);
let total = ref(0);
// 查询表单
const searchForm = reactive({
  appId: "",
  msgContent: "",
  mobile: "",
  attribution: null,
  startTime: null,
  endTime: null,
  page: 1,
  pageSize: 100,
});
const query = async () => {
  loading.value = true;
  let obj = { ...searchForm };
  if (obj.startTime > obj.endTime) {
    ElMessage({
      message: t("开始时间不能大于结束时间！"),
      type: "warning",
    });
    return;
  }
  if (!obj.startTime && obj.endTime) {
    obj.startTime = dayjs(obj.endTime).startOf("date").format();
  }
  obj.startTime ||= dayjs().startOf("date").format();
  obj.endTime ||= dayjs().format();
  // obj.groupId = obj.groupId * 1;
  const res = await getMoLogList(obj);
  if (res.code === 0) {
    tableData.value = [];
    total.value = res.data.total;
    let list = res.data.list || [];
    for (let i = 0; i < list.length; i++) {
      let mobile2 = list[i].mobile;
      list[i].mobile = encryptPhone(list[i].mobile);
      tableData.value.push({
        ...list[i],
        mobile2,
      });
    }
  }
  loading.value = false;
};

const getMobile = (index) => {
  tableData.value[index].mobile = tableData.value[index].mobile2;
};

// 处理响应代码文本
const responseCode_content = (txt) => {
  let arr = txt.split(",");
  let str = "";
  arr.forEach((item) => {
    str += `${item}<br>`;
  });
  return str;
};
const copy = (txt) => {
  let str = "";
  txt.split(",").forEach((item) => {
    str += `${item}\n`;
  });
  onCopy(str);
  ElMessage({
    type: "success",
    message: t("复制成功"),
  });
};
</script>
        
  <style lang="scss" scoped>
.elTable {
  --height: calc(100vh - 330px);
}

::v-deep(label) {
  margin-bottom: 0;
}
</style>
  <style lang="scss">
</style>
        